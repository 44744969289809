import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _76b43121 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _5c831c73 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _5d0cae3a = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _3bb28d3b = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _77e89ba2 = () => interopDefault(import('../pages/auth/check.vue' /* webpackChunkName: "pages/auth/check" */))
const _d708f70e = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _3487eec6 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _a7769b14 = () => interopDefault(import('../pages/forum/form/index.vue' /* webpackChunkName: "pages/forum/form/index" */))
const _5f2cea38 = () => interopDefault(import('../pages/auth/reset/_slug.vue' /* webpackChunkName: "pages/auth/reset/_slug" */))
const _b9ae4b44 = () => interopDefault(import('../pages/forum/form/_id.vue' /* webpackChunkName: "pages/forum/form/_id" */))
const _41f8c0ca = () => interopDefault(import('../pages/forum/_id.vue' /* webpackChunkName: "pages/forum/_id" */))
const _2640dd3c = () => interopDefault(import('../pages/forum/_idOld.vue' /* webpackChunkName: "pages/forum/_idOld" */))
const _8e87b5be = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _76b43121,
    name: "admin"
  }, {
    path: "/forum",
    component: _5c831c73,
    name: "forum"
  }, {
    path: "/home",
    component: _5d0cae3a,
    name: "home"
  }, {
    path: "/profile",
    component: _3bb28d3b,
    name: "profile"
  }, {
    path: "/auth/check",
    component: _77e89ba2,
    name: "auth-check"
  }, {
    path: "/auth/forgot",
    component: _d708f70e,
    name: "auth-forgot"
  }, {
    path: "/auth/success",
    component: _3487eec6,
    name: "auth-success"
  }, {
    path: "/forum/form",
    component: _a7769b14,
    name: "forum-form"
  }, {
    path: "/auth/reset/:slug?",
    component: _5f2cea38,
    name: "auth-reset-slug"
  }, {
    path: "/forum/form/:id",
    component: _b9ae4b44,
    name: "forum-form-id"
  }, {
    path: "/forum/:id",
    component: _41f8c0ca,
    name: "forum-id"
  }, {
    path: "/forum/:idOld",
    component: _2640dd3c,
    name: "forum-idOld"
  }, {
    path: "/",
    component: _8e87b5be,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
